import { SUPPORTED_LANGUAGES } from "~/config/constants";
import { useLanguage } from "~/context/LanguageContext";

export const getLocalizedPagePath = (path: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { lang } = useLanguage();

  if (!SUPPORTED_LANGUAGES.includes(lang) || lang === "en") {
    return path;
  }

  return `/${lang}${path}`;
};
