import { isRouteErrorResponse, useRouteError } from "@remix-run/react";

export const rootMeta = [
  {
    name: "description",
    content:
      "SkillSynergy is an innovative learning platform that offers personalized and group-based online programming courses for all skill levels, from beginners to advanced. Through flexible, structured learning paths with varying intensity levels (Light, Semi-Intensive, Intensive), students gain hands-on experience in programming, algorithms, web development, and mobile development. With expert instructors, career guidance, and job interview preparation, SkillSynergy ensures that learners are equipped with the skills they need to succeed in today's tech-driven world.",
  },
  {
    name: "keywords",
    content: [
      "SkillSynergy",
      "programming courses",
      "online programming courses",
      "programming",
      "algorithms",
      "web development",
      "mobile development",
      "career guidance",
      "job interview preparation",
      "tech-driven world",
      "programming skills",
      "certification",
    ],
  },
  { name: "viewport", content: "width=device-width, initial-scale=1" },
  { name: "charset", content: "utf-8" },

  // Open Graph Meta Tags
  {
    property: "og:title",
    content: "SkillSynergy - Empower Your Coding Journey",
  },
  {
    property: "og:description",
    content:
      "SkillSynergy provides personalized and group-based programming courses for all levels. Choose from Light, Semi-Intensive, and Intensive tracks, and gain hands-on experience with expert instructors.",
  },
  {
    property: "og:image",
    content: "https://skillsynergy.net/social_post_card.png",
  }, // Replace with the actual image path
  { property: "og:url", content: "https://skillsynergy.net" },
  { property: "og:type", content: "website" },
  { property: "og:site_name", content: "SkillSynergy" },

  // Twitter Card Meta Tags
  { name: "twitter:card", content: "summary_large_image" },
  {
    name: "twitter:title",
    content: "SkillSynergy - Empower Your Coding Journey",
  },
  {
    name: "twitter:description",
    content:
      "Discover SkillSynergy's tailored programming courses for all levels. Join Light, Semi-Intensive, or Intensive paths and prepare for a career in tech.",
  },
  {
    name: "twitter:image",
    content: "https://skillsynergy.net/social_post_card.png",
  },
  { name: "twitter:site", content: "@SkillSynergyDotNet" },

  // Additional tags for enhanced SEO
  { name: "robots", content: "index, follow" },
  { name: "author", content: "SkillSynergy Team" },
  { name: "canonical", content: "https://skillsynergy.net" },
];

export default () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return [
        {
          title: "SkillSynergy - 404",
          description: "The page you're looking for does not exist.",
        },
      ];
    }

    return [
      {
        title: `SkillSynergy - ${error.status}`,
        description: error.statusText || "An unexpected error occurred.",
      },
    ];
  }

  return [{ title: "SkillSynergy" }, ...rootMeta];
};
