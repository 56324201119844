// app/context/LanguageContext.tsx
import React, { ReactNode, createContext, useContext } from "react";

export type LanguageContextType = {
  lang: "en" | "fr" | "de" | "ar";
};

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined,
);

export const LanguageProvider: React.FC<{
  children: ReactNode;
  lang: LanguageContextType["lang"];
}> = ({ children, lang }) => {
  return (
    <LanguageContext.Provider value={{ lang }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
